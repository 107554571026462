import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button/Button'
import CardContainer from '../../components/CardContainer/CardContainer'
import Loader from '../../components/Loader/Loader'
import ActionsModal from '../../components/Modals/ActionsModal'
import DeleteModal from '../../components/Modals/DeleteModal'
import LoadingModal from '../../components/Modals/LoadingModal'
import NetworkModal from '../../components/Modals/NetworkModal'
import PageSidebarContainer from '../../components/PageSidebarContainer'
import NetworksTable from '../../components/Tables/NetworksTable'
import TextInput from '../../components/TextInput/TextInput'
import { getNetworkDetail, getNetworks } from '../../resources/api-constants'
import { COLORS } from '../../resources/colors'
import { ROUTES } from '../../resources/routes-constants'
import { setLastSearchNetwork, setPageHeader } from '../../store/actions/graphic'
import { fetchNetworks } from '../../store/actions/thunk_actions'
import { Network } from '../../types/data'
import { Reducers, ReducerGraphic, ReducerUser } from '../../types/reducers'
import FmWorldAxios from '../../utility/FmWorldAxios'
import FmWorldMultipartAxios from '../../utility/FmWorldMultipartAxios'
import { Grants } from '../../utility/Grants'
import { usePrevious } from '../../utility/hooks/common'
import { voidNetwork, VoidNetwork } from '../../utility/voidConstants'

const NetworksListPage: React.FC = () => {
    const [search, setSearch] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isCreating, setIsCreating] = useState(false)
    const [error, setError] = useState('')
    const [networks, setNetworks] = useState<Network[]>([])
    const [filteredNetworks, setFilteredNetworks] = useState<Network[]>([])
    const [selectedNetwork, setSelectedNetwork] = useState<Network | null>(null)
    const [networkToDelete, setNetworkToDelete] = useState<Network | null>(null)
    const [networkToEdit, setNetworkToEdit] = useState<Network | VoidNetwork | null>(null)
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const previousSearch = usePrevious(search)
    const { t } = useTranslation()

    const fetchNetworksList = async () => {
        setError('')
        setSearch('')
        setIsLoading(true)
        try {
            const networksFetch = await FmWorldAxios.get(getNetworks()).then((res) => {
                return res.data.items
            })
            setNetworks(networksFetch)
            setFilteredNetworks(networksFetch)
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsLoading(false)
    }

    useEffect(() => {
        dispatch(
            setPageHeader({
                parent: null,
                name: t('headers.networks'),
            })
        )
    }, [])

    useEffect(() => {
        if (user.user) {
            void fetchNetworksList()
            if (graphic.lastNetworkSearch) {
                setSearch(graphic.lastNetworkSearch?.search)
            }
        }
    }, [user.user])

    useEffect(() => {
        if (previousSearch && graphic.lastRadioSearch) {
            dispatch(setLastSearchNetwork(null))
        }
        if (search.length > 2) {
            const filtered = networks.filter((n) => n.name.toLowerCase().includes(search.toLowerCase()))
            setFilteredNetworks(filtered)
        } else {
            setFilteredNetworks(networks)
        }
    }, [search, networks])

    useEffect(() => {
        if (search.length > 2) {
            dispatch(setLastSearchNetwork({ search, page: 1 }))
        } else {
            dispatch(setLastSearchNetwork(null))
        }
    }, [filteredNetworks])

    const actionsModal = () => {
        const actions = [
            {
                text: t('networks.view_network'),
                color: COLORS.blueGrey,
                onClick: () =>
                    navigate(`${ROUTES.NETWORK_DETAIL_ROUTE}${selectedNetwork?.id}`, {
                        state: { name: selectedNetwork?.name },
                    }),
            },
        ]
        if (user.user?.grants.includes(Grants.EDIT_ALL_NETWORKS)) {
            actions.push({
                text: t('networks.edit_network'),
                color: COLORS.purple,
                onClick: () => {
                    setSelectedNetwork(null)
                    setNetworkToEdit(selectedNetwork)
                },
            })
        }
        if (user.user?.grants.includes(Grants.DELETE_ALL_NETWORKS)) {
            actions.push({
                text: t('networks.delete_network'),
                color: COLORS.redDelete,
                onClick: () => {
                    setSelectedNetwork(null)
                    setNetworkToDelete(selectedNetwork)
                },
            })
        }
        return actions
    }

    const handleUpdateNetwork = async (network: Network) => {
        setError('')
        setIsUpdating(true)
        try {
            const updated = {
                name: network.name,
                logo: network.logo,
                email: network.email,
                referentFirstName: network.referentFirstName,
                referentLastName: network.referentLastName,
                phoneNumber: String(network.phoneNumber),
            }
            const formData = new FormData()
            Object.keys(updated).map((key) => {
                const currentField = updated[key as keyof typeof updated]
                formData.append(key, currentField !== null ? String(currentField) : '')
                return
            })
            await FmWorldMultipartAxios.put(getNetworkDetail(network.id), formData)
            setSelectedNetwork(null)
            setNetworkToEdit(null)
            await fetchNetworksList()
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsUpdating(false)
    }

    const handleCreateNetwork = async (network: VoidNetwork) => {
        setError('')
        setIsCreating(true)
        try {
            const formData = new FormData()
            Object.keys(network).map((key) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const currentField = network[key as any]
                formData.append(key, Array.isArray(currentField) ? JSON.stringify(currentField) : currentField)
                return
            })
            await FmWorldMultipartAxios.post(getNetworks(), formData)
            dispatch(fetchNetworks())
            setSelectedNetwork(null)
            setNetworkToEdit(null)
            await fetchNetworksList()
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsCreating(false)
    }

    const handleDeleteNetwork = async () => {
        if (networkToDelete) {
            setError('')
            setIsLoading(true)
            try {
                await FmWorldAxios.delete(getNetworkDetail(networkToDelete.id))
                dispatch(fetchNetworks())
                setNetworkToDelete(null)
                setSelectedNetwork(null)
                await fetchNetworksList()
            } catch (error: any) {
                console.log('ERROR', error)
                setError(error.toString())
            }
            setIsLoading(false)
        }
    }

    return (
        <PageSidebarContainer>
            <CardContainer>
                <div className="titleRow">
                    <div style={{ flex: 1 }}>
                        <h2 className="title">{t('networks.your_network')}</h2>
                        <h3 className="subtitle" style={{ fontWeight: 400 }}>
                            {t('networks.manage_your_networks')}
                        </h3>
                        {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                    </div>
                    <div
                        style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}
                    >
                        <div style={{ flex: 1 }} />
                        <div style={{ flex: 2 }}>
                            <TextInput
                                value={search}
                                placeholder={t('general.search')}
                                icon="search"
                                iconPosition="right"
                                onTextChange={(v) => setSearch(v)}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Button
                                label={t('general.refresh')}
                                onPress={() => {
                                    return
                                }}
                                type="submit"
                                background="rgba(100,38,119,0.14)"
                                border={`2px solid ${COLORS.purple}`}
                                color={COLORS.purple}
                            />
                        </div>
                        {user.user?.grants.includes(Grants.CREATE_NETWORK) ? (
                            <div style={{ flex: 1 }}>
                                <Button
                                    label={t('general.add')}
                                    onPress={() => {
                                        setNetworkToEdit(voidNetwork)
                                    }}
                                    type="submit"
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
                {isLoading ? (
                    <div className="loadingGrid">
                        <Loader />
                    </div>
                ) : (
                    <div style={{ alignItems: 'flex-start', marginTop: 20 }}>
                        <NetworksTable data={filteredNetworks} selectNetwork={(n) => setSelectedNetwork(n)} />
                    </div>
                )}
            </CardContainer>
            <ActionsModal
                isVisible={selectedNetwork !== null}
                title={t('general.what_to_do')}
                onClose={() => setSelectedNetwork(null)}
                actions={actionsModal()}
            />
            <NetworkModal
                isVisible={networkToEdit !== null}
                onClose={() => setNetworkToEdit(null)}
                onSave={(network) => {
                    if (network && (network as Network).id) {
                        void handleUpdateNetwork(network as Network)
                    } else {
                        void handleCreateNetwork(network as VoidNetwork)
                    }
                }}
                network={networkToEdit}
            />
            <DeleteModal
                onDelete={() => {
                    void handleDeleteNetwork()
                }}
                title={`${t('networks.delete_this_network')} ${networkToDelete?.name}`}
                text={`${t('networks.want_delete_network')} ${networkToDelete?.name}?`}
                onClose={() => {
                    setNetworkToDelete(null)
                }}
                isVisible={networkToDelete !== null}
            />
            <LoadingModal isVisible={isCreating || isUpdating} text={t('loadings.updating')} />
        </PageSidebarContainer>
    )
}

export default NetworksListPage
