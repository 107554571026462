import MaterialTable, { Column } from 'material-table'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Reducers, ReducerData, ReducerGraphic, ReducerUser } from '../../types/reducers'
import { getLangTable } from '../../utility/functions'
import { Languages } from '../../utility/Languages'
import FixedTableTheme from '../FixedTableTheme'
import { Grants } from '../../utility/Grants'
import { User } from '../../types/user'

interface Props {
    data: User[]
    selectUser: (user: User) => void
    showActions?: boolean
}

const GeneralUsersTable: React.FC<Props> = (props) => {
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const dataRedux = useSelector<Reducers, ReducerData>((state) => state.data)
    const loggedUser = useSelector<Reducers, ReducerUser>((state) => state.user)
    const { t } = useTranslation()

    const columns: Column<User>[] = [
        {
            title: t('general.name') as string,
            field: 'name',
            render: (user: User) => {
                return (
                    <p>
                        {user.firstName} {user.lastName}
                        <b>{user.id === loggedUser.user?.id ? `  (${t('general.you')}) ` : ''}</b>
                    </p>
                )
            },
        },
        {
            title: t('auth.email') as string,
            field: 'email',
            render: (user: User) => {
                return <p>{user.email}</p>
            },
        },
        {
            title: t('networks.role') as string,
            field: 'role',

            render: (user: User) => {
                return <p>{user.role?.description}</p>
            },
        },
    ]

    const users = useMemo(() => {
        console.log(props.data)
        return props.data
    }, [props.data])

    const actions = useMemo(() => {
        const actions = [
            (user: User) => ({
                icon: 'more_vert',
                onClick: () => {
                    {
                        loggedUser.user?.grants.includes(Grants.EDIT_NETWORK) ? props.selectUser(user) : null
                    }
                },
            }),
        ]

        return actions
    }, [props.data])

    return (
        <FixedTableTheme>
            <MaterialTable
                columns={columns}
                data={users}
                actions={actions}
                title=""
                localization={getLangTable(graphic.currentLanguage as Languages)}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50],
                    actionsCellStyle: {
                        border: '0',
                    },
                    toolbar: false,
                }}
                style={{
                    boxShadow: 'none',
                }}
            />
        </FixedTableTheme>
    )
}

export default GeneralUsersTable
