import React, { useEffect, useMemo, useState } from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import Button from '../Button/Button'
import { COLORS } from '../../resources/colors'
import TextInput from '../TextInput/TextInput'
import { emailRegEx } from '../../utility/functions'
import { User } from '../../types/user'
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material'
import { LanguagesArray } from '../../utility/Languages'

interface Props {
    isVisible: boolean
    onClose: () => void
    onSave: (email: string, name?: string, surname?: string, language?: string) => void
    userData?: User | null
    idNetwork: number
}

const UserListModal: React.FC<Props> = (props) => {
    const [email, setEmail] = useState(props.userData ? props.userData.email : '')
    const [name, setName] = useState(props.userData ? props.userData.firstName : '')
    const [surname, setSurname] = useState(props.userData ? props.userData.lastName : '')
    const [language, setLanguage] = useState(props.userData ? props.userData.language : '')

    useEffect(() => {
        if (props.isVisible) {
            setEmail(props.userData ? props.userData.email : '')
            setName(props.userData ? props.userData.firstName : '')
            setSurname(props.userData ? props.userData.lastName : '')
            setLanguage(props.userData ? props.userData.language : '')
        }
    }, [props.isVisible, props.userData])

    const checkDataIsValid = useMemo(() => {
        return email && emailRegEx.test(email)
    }, [email])

    return (
        <Modal
            open={props.isVisible}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox actionBox">
                <h2 className="modalBox-title">
                    {props.userData
                        ? `Modifica utente ${props.userData.firstName} ${props.userData.lastName}`
                        : 'Aggiungi utente'}
                </h2>
                <div>
                    <TextInput
                        value={email}
                        onTextChange={(e) => setEmail(e)}
                        label="Email"
                        placeholder="Email"
                        disabled={!!props.userData}
                    />
                    <TextInput value={name} onTextChange={(e) => setName(e)} label="Name" placeholder="Name" />
                    <TextInput
                        value={surname}
                        onTextChange={(e) => setSurname(e)}
                        label="Surname"
                        placeholder="Surname"
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="language-select-label">Language</InputLabel>
                        <Select
                            labelId="language-select-label"
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                            label="Language"
                        >
                            <MenuItem value="">
                                <em>Select Language</em>
                            </MenuItem>
                            {LanguagesArray.map((lang) => (
                                <MenuItem key={lang.id} value={lang.id}>
                                    {lang.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="modalBox-buttonsRow">
                    <div style={{ flex: '1 1 100%' }} />
                    <Button
                        label="Annulla"
                        border={'1px solid rgba(0,0,0,0.07)'}
                        onPress={props.onClose}
                        type="secondary"
                        color={COLORS.darkGrey}
                        background="#fff"
                    />
                    <Button
                        disabled={!checkDataIsValid}
                        label={props.userData ? 'Aggiorna' : 'Aggiungi'}
                        type="submit"
                        color="#fff"
                        background={COLORS.purple}
                        onPress={() => {
                            props.onSave(email, name, surname, language)
                        }}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default UserListModal
