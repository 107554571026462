import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Loader from '../components/Loader/Loader'
import Button from '../components/Button/Button'

import ActionsModal from '../components/Modals/ActionsModal'
import DeleteModal from '../components/Modals/DeleteModal'
import LoadingModal from '../components/Modals/LoadingModal'
import TextInput from '../components/TextInput/TextInput'
import { getUsers, getUsersDetail } from '../resources/api-constants'
import { COLORS } from '../resources/colors'
import { Reducers, ReducerData, ReducerUser } from '../types/reducers'
import FmWorldAxios from '../utility/FmWorldAxios'
import { Grants } from '../utility/Grants'
import PageSidebarContainer from '../components/PageSidebarContainer'
import GeneralUsersTable from '../components/Tables/GeneralUsersTable'
import CardContainer from '../components/CardContainer/CardContainer'
import UserListModal from '../components/Modals/UserListModal'
import { User } from '../types/user'

const UserListPage: React.FC = () => {
    const [search, setSearch] = useState('')
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isCreating, setIsCreating] = useState(false)
    const [selectUserForActions, setSelectUserForActions] = useState<User | null>(null)
    const [editUser, setEditUser] = useState<User | null>(null)
    const [addUserModal, setAddUserModal] = useState(false)
    const [deletedUser, setDeletedUser] = useState<User | null>(null)
    const [users, setUsers] = useState<User[]>([])
    const [filteredUsers, setFilteredUsers] = useState<User[]>([])
    const { t } = useTranslation()
    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const selectedNetwork = data.selectedNetwork
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)

    const fetchUsers = async () => {
        setError('')
        setIsLoading(true)
        try {
            if (!selectedNetwork) {
                setError(t('networks.no_network_selected') || '')
                return
            }
            const response = await FmWorldAxios.get(getUsers())
            const usersFetch: { count: number; items: User[] } = response.data
            setEditUser(null)
            setUsers(usersFetch.items)
        } catch (error: any) {
            console.error('ERROR fetching users:', error)
            setError(error.response?.data?.message || error.toString())
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (selectedNetwork?.id) {
            void fetchUsers()
        }
    }, [selectedNetwork?.id])

    useEffect(() => {
        if (search) {
            const filtered = users.filter(
                (u) =>
                    u.firstName.toLowerCase().includes(search.toLowerCase()) ||
                    u.lastName.toLowerCase().includes(search.toLowerCase()) ||
                    u.email.toLowerCase().includes(search.toLowerCase())
            )
            setFilteredUsers(filtered)
        } else {
            setFilteredUsers(users)
        }
    }, [search, users])

    const actionsModal = () => {
        const actions = []
        if (user.user?.grants.includes(Grants.EDIT_NETWORK_USERS)) {
            actions.push({
                text: t('networks.edit_user'),
                color: COLORS.purple,
                onClick: () => {
                    setEditUser(selectUserForActions)
                    setSelectUserForActions(null)
                },
            })
        }
        if (selectUserForActions?.id === user.user?.id) {
            return actions
        }

        if (user.user?.grants.includes(Grants.DELETE_NETWORK_USERS) && user.user?.id !== selectUserForActions?.id) {
            actions.push({
                text: t('networks.delete_user'),
                color: COLORS.redDelete,
                onClick: () => {
                    setDeletedUser(selectUserForActions)
                    setSelectUserForActions(null)
                },
            })
        }

        return actions
    }

    const handleEditUser = async (email: string, name?: string, surname?: string, language?: string) => {
        if (editUser) {
            setError('')
            setIsUpdating(true)
            try {
                if (!selectedNetwork) {
                    setError(t('networks.no_network_selected') || '')
                    return
                }
                const body = {
                    email,
                    firstName: name ?? '',
                    lastName: surname ?? '',
                    language: language ?? '',
                }
                await FmWorldAxios.put(getUsersDetail(editUser.id), body)
                await fetchUsers()
                setEditUser(null)
                setAddUserModal(false)
            } catch (error: any) {
                console.error('ERROR editing user:', error)
                setError(error.response?.data?.message || error.toString())
            }
            setIsUpdating(false)
        }
    }

    const handleCreateUser = async (email: string, name?: string, surname?: string, language?: string) => {
        setError('')
        setIsCreating(true)
        try {
            if (!selectedNetwork) {
                setError(t('networks.no_network_selected') || '')
                return
            }
            const body = {
                email,
                firstName: name ?? '',
                lastName: surname ?? '',
                language: language ?? '',
            }
            await FmWorldAxios.post(getUsers(), body)
            setEditUser(null)
            setAddUserModal(false)
            await fetchUsers()
        } catch (error: any) {
            console.error('ERROR creating user:', error)
            setError(error.response?.data?.message || error.toString())
        }
        setIsCreating(false)
    }

    const handleDeleteUser = async () => {
        if (deletedUser) {
            setError('')
            setIsLoading(true)
            try {
                if (!selectedNetwork) {
                    setError(t('networks.no_network_selected') || '')
                    return
                }
                await FmWorldAxios.delete(getUsersDetail(deletedUser.id))
                await fetchUsers()
                setDeletedUser(null)
            } catch (error: any) {
                console.error('ERROR deleting user:', error)
                setError(error.response?.data?.message || error.toString())
            }
            setIsLoading(false)
        }
    }

    if (isLoading) {
        return (
            <div className="loadingGrid">
                <Loader />
            </div>
        )
    }

    return (
        <PageSidebarContainer>
            <CardContainer>
                <div>
                    <div className="titleRow">
                        <div style={{ flex: 1 }}>
                            <h2 className="title">{t('placeholders.users')}</h2>
                            <h3 className="subtitle" style={{ fontWeight: 400 }}>
                                {t('placeholders.manage_users_general')}
                            </h3>
                            {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flex: 2,
                                gap: 10,
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <div style={{ flex: 1 }} />
                            <div style={{ flex: 2 }}>
                                <TextInput
                                    value={search}
                                    placeholder={t('general.search')}
                                    icon="search"
                                    iconPosition="right"
                                    onTextChange={(v) => setSearch(v)}
                                />
                            </div>
                            <div style={{ flex: 1 }}>
                                <Button
                                    label={t('general.refresh')}
                                    onPress={() => {
                                        setSearch('')
                                        void fetchUsers()
                                    }}
                                    type="submit"
                                    background="rgba(100,38,119,0.14)"
                                    border={`2px solid ${COLORS.purple}`}
                                    color={COLORS.purple}
                                />
                            </div>
                            {user.user?.grants.includes(Grants.ADD_NETWORK_USERS) ? (
                                <div style={{ flex: 1 }}>
                                    <Button
                                        label={t('general.add')}
                                        onPress={() => {
                                            setAddUserModal(true)
                                        }}
                                        type="submit"
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div>
                        <GeneralUsersTable
                            showActions={actionsModal().length > 0}
                            data={filteredUsers}
                            selectUser={(u) => setSelectUserForActions(u)}
                        />
                    </div>
                    <ActionsModal
                        isVisible={selectUserForActions !== null}
                        title={t('general.what_to_do')}
                        onClose={() => setSelectUserForActions(null)}
                        actions={actionsModal()}
                    />
                    <UserListModal
                        isVisible={addUserModal || editUser !== null}
                        userData={editUser}
                        onClose={() => {
                            setEditUser(null)
                            setAddUserModal(false)
                        }}
                        idNetwork={selectedNetwork?.id || 0}
                        onSave={(email, name, surname, language) => {
                            if (editUser) {
                                void handleEditUser(email, name, surname, language)
                            } else {
                                void handleCreateUser(email, name, surname, language)
                            }
                        }}
                    />
                    <DeleteModal
                        onDelete={() => {
                            void handleDeleteUser()
                        }}
                        title={`${t('networks.delete_this_user')} ${
                            deletedUser?.firstName ? deletedUser.firstName : deletedUser?.email
                        } ${deletedUser?.lastName ? deletedUser.lastName : ''}`}
                        text={`${t('networks.want_delete_user')} ${
                            deletedUser?.firstName ? deletedUser.firstName : deletedUser?.email
                        } ${deletedUser?.lastName ? deletedUser.lastName : ''}?`}
                        onClose={() => {
                            setDeletedUser(null)
                        }}
                        isVisible={deletedUser !== null}
                    />
                    <LoadingModal
                        isVisible={isCreating || isUpdating}
                        text={isCreating ? t('loadings.creating_user') : t('loadings.updating_user')}
                    />
                </div>
            </CardContainer>
        </PageSidebarContainer>
    )
}

export default UserListPage
